.App {
  text-align: center;
}

main {
  padding: 20vh 6vw 0 6vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
