.navbar {
  background-color: #fff;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0em;
  position: fixed;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  padding: 1.8vh 6vw;
  pointer-events: auto;
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  animation: fadeIn 3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .nav-logo-wrapper {
    @media screen and (max-width: 767px) {
      width: 25%;
    }
  }

  .nav-logo {
    width: auto;
    max-width: 100%;
    max-height: 50px;
    margin: 2vh 0;
    border: 0;
    cursor: pointer;
    height: fit-content;
    object-fit: contain;
  }

  .nav-menu {
    display: flex;
    align-items: center;

    &.expand {
      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
      }
    }

    @media screen and (max-width: 1024px) {
      display: none;
    }

    .nav-pages {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
      }

      .nav-link {
        padding: 4vh 1vw;

        a {
          color: black;
          text-decoration: none;
          text-underline-offset: 0.5rem;
        }
      }
    }

    .nav-social-links {
      display: flex;
      flex-direction: row;
      height: 100%;

      .nav-social-link {
        margin-left: 2.5vw;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;

        .nav-social-icon {
          width: auto;
          max-width: 100%;
          max-height: 50px;
          border: 0;
          cursor: pointer;
          height: fit-content;
          object-fit: contain;
        }
      }
    }
  }

  .hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 2.5vh;
    width: 25%;

    @media screen and (min-width: 1025px) {
      display: none;
    }

    img {
      height: 5vh;
    }
  }
}
