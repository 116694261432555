.footer-social-links {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;

  .footer-social-link {
    margin-left: 2.5vw;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;

    .footer-social-icon {
      width: auto;
      max-width: 100%;
      max-height: 50px;
      border: 0;
      cursor: pointer;
      height: fit-content;
      object-fit: contain;
    }
  }
}
