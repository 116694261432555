.post-wrapper {
  display: flex;
  justify-content: center;

  .post {
    width: 50%;

    @media screen and (max-width: 1024px) {
      width: 90%;
    }

    .post-title {
      font-family: "Roboto";
      font-size: calc(1.6vw + 1rem);
      text-align: left;
    }

    .post-content {
      text-align: left;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .post-content-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin: auto;

        img {
          width: 100%;
          object-fit: contain;
          height: auto;
          max-height: 70vh;
        }

        figcaption {
          font-family: "Lato";
          font-style: italic;
          font-size: 1.1rem;
          margin-top: 1rem;
        }
      }

      .wp-block-embed-youtube {
        iframe {
          width: 100%;
        }
      }
    }
  }
}
