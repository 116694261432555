.post-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 100px;

  animation: fadeIn 3s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  img {
    margin-right: 5%;
    width: 40%;
    min-width: 40%;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 5vw;
    }
  }

  .post-card-text {
    width: 60%;
    display: flex;
    flex-direction: column;
    text-align: left;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    .post-category {
      margin-top: 0;
    }

    .post-card-title {
      cursor: pointer;
      font-family: "Roboto";
      font-size: calc(2vw + 1rem);
      letter-spacing: 1px;
      margin: 0 0 1rem 0;
    }

    .post-card-excerpt {
      font-family: "Lato";
      font-style: italic;
      font-size: 1.1rem;
    }

    .read-more-button {
      margin-top: 0.5vw;
      color: #fc221c;
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 0.5rem;
      font-size: 1rem;
    }
  }
}
